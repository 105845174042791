<template>
  <div>
    <div class="heatmap-legend">
      <v-chip :color="leg.color" outlined class="d-flex align-center v-chip--active" v-for="leg in legend">
        <v-sheet
          width="20"
          height="20"
          :class="{ 'pa-1': true, 'heatmap-cell': true, 'dotted': leg.dotted, 'rounded' : true }"
          :color="leg.color"
        />
        <span class="caption ml-1 text-nowrap">{{ leg.desc }}</span>
      </v-chip>
    </div>
    <table style="border-collapse: collapse">
      <tbody>
        <tr class="heatmap-row" v-for="cli in impagadosParsed" :key="cli.IdCliente">
          <td>
            <table-button
              small
              color="secondary"
              tooltip="Detalles del cliente"
              :to="`/clientes/detalles?idCliente=${cli.IdCliente}`"
            >{{ cli.denominacion }}</table-button>
          </td>
          <td class="px-1 d-flex pa-1 align-center" style="gap: 8px">
            <component
              v-for="imp in cli.impagados"
              :key="imp.IdCarteraCobro"
              :is="getCellType(imp)"
            />
          </td>
          <td class="pl-2">
            <span class="subtitle-1">{{ cli.deuda }} €</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { getSituacionIcon, perColumnFilter } from '@/utils/index.js'
import colors from 'vuetify/lib/util/colors'
import Vue from 'vue';

export default {
  components: {
    TableButton: () => import('@/components/TableButton.vue')
  },
  props: {
    impagados: Array,
    filters: Object,
    headers: Array
  },
  data() {
    return {
      allColors: {},
      legend: [
        { desc: 'Comprometido Pago', filter: (imp) => imp.estadoPropio == 'Comprometido Pago', dotted: false, color: getSituacionIcon('Comprometido Pago').color },

        { desc: 'Abonado', filter: (imp) => imp.estadoPropio == 'Abonado', dotted: false, color: getSituacionIcon('Abonado').color },

        { desc: 'Fraccionado 3 Pagos', filter: (imp) => imp.estadoPropio == 'Fraccionado 3 Pagos', dotted: false, color: getSituacionIcon('Fraccionado 3 Pagos').color },

        { desc: 'Fraccionado 2 Pagos', filter: (imp) => imp.estadoPropio == 'Fraccionado 2 Pagos', dotted: false, color: getSituacionIcon('Fraccionado 2 Pagos').color },

        { desc: '<20 días hasta vencimiento (sin contactar)', filter: (imp) => (imp.nLlamadas == 0 && imp.nEmails == 0) && imp.diasDesdeVencimiento < 20, dotted: true, color: 'primary' },

        { desc: '<20 días hasta vencimiento', filter: (imp) => (imp.nLlamadas != 0 || imp.nEmails != 0) && imp.diasDesdeVencimiento < 20, dotted: false, color: 'primary' },

        { desc: 'Carta de Corte (sin enviar)', filter: (imp) => Number(imp.cartaCorte) || (imp.diasDesdeVencimiento >= 41 && Number(imp.carta1) && Number(imp.carta2) && !Number(imp.cartaCorte)), dotted: true, color: getSituacionIcon('Carta de Corte').color },

        { desc: 'Carta de Corte', filter: (imp) => Number(imp.cartaCorte) || (imp.diasDesdeVencimiento >= 41 && Number(imp.carta1) && Number(imp.carta2) && Number(imp.cartaCorte)), dotted: false, color: getSituacionIcon('Carta de Corte').color },

        { desc: '2ª Carta (sin enviar)', filter: (imp) => Number(imp.carta2) || (imp.diasDesdeVencimiento >= 35 && Number(imp.carta1) && !Number(imp.cartaCorte) && Number(imp.carta1) && !Number(imp.carta2)), dotted: true, color: getSituacionIcon('2ª Carta').color },

        { desc: '2ª Carta', filter: (imp) => Number(imp.carta2) || (imp.diasDesdeVencimiento >= 35 && Number(imp.carta1) && !Number(imp.cartaCorte) && Number(imp.carta2)), dotted: false, color: getSituacionIcon('2ª Carta').color },

        { desc: '1ª Carta (sin enviar)', filter: (imp) => imp.diasDesdeVencimiento >= 20 && !Number(imp.carta2) && !Number(imp.cartaCorte) && !Number(imp.carta1), dotted: true, color: getSituacionIcon('1ª Carta').color },

        { desc: '1ª Carta', filter: (imp) => imp.diasDesdeVencimiento >= 20 && !Number(imp.carta2) && !Number(imp.cartaCorte) && Number(imp.carta1), dotted: false, color: getSituacionIcon('1ª Carta').color },

      ]
    }
  },
  computed: {
    impagadosParsed() {
      const getDatatype = (key) =>
        (this.headers.find((h) => h.value == key) || {}).dataType;

      // let aux = this.impagados.sort((a, b) =>
      //   b.diasDesdeVencimiento - a.diasDesdeVencimiento || b.ImporteTotal - a.ImporteTotal
      // );
      const impagadosFiltrados = this.impagados.filter((con) =>
        Object.entries(con).every(([key, value]) =>
          perColumnFilter(value, this.filters[key], getDatatype(key))
        )
      );

      const clientes = impagadosFiltrados.filter((imp, index, self) =>
        index === self.findIndex((t) => (
          t.IdCliente == imp.IdCliente
        ))
      ).map(imp => ({ IdCliente: imp.IdCliente, denominacion: imp.denominacion, Identificador: imp.Identificador }))

      clientes.forEach(cli => {
        cli.impagados = impagadosFiltrados.filter(imp => imp.IdCliente == cli.IdCliente).sort((a, b) => b.diasDesdeVencimiento - a.diasDesdeVencimiento);
        cli.deuda = parseFloat(cli.impagados.reduce((a, c) => Number(c.ImporteTotal) + a, 0)).toFixed(2)
      });

      return clientes.sort((a, b) => b.impagados.length - a.impagados.length || b.deuda - a.deuda )
    }
  },
  mounted() {
    // console.log(Vue.component)
    let auxColors = { ...this.$vuetify.theme.currentTheme };
    Object.keys(auxColors).map((key, index) => auxColors[key] = { 'base': auxColors[key] })
    this.allColors = { ...colors, ...(auxColors) };
  },
  methods: {
    getSituacionIcon,
    hexColor(name) {
      if (/^#(?:[0-9a-f]{3}){1,2}$/i.test(name)) return name;
      const [nameFamily, nameModifier] = name.split(' ')
      const shades = ['black', 'white', 'transparent']
      const util = { family: null, modifier: null }
      if (shades.find(shade => shade === nameFamily)) {
        util.family = 'shades'
        util.modifier = nameFamily
      } else {
        const [firstWord, secondWord] = nameFamily.split('-')
        util.family = `${firstWord}${secondWord
          ? secondWord.charAt(0).toUpperCase() + secondWord.slice(1)
          : ''}`
        util.modifier = nameModifier
          ? nameModifier.replace('-', '')
          : 'base'
      }
      return this.allColors[util.family][util.modifier]
    },
    getCellType(imp) {
      const self = this;
      for (const leg of this.legend) {
        if (leg.filter(imp)) return Vue.component('heatmap-cell', {
          functional: true,
          render: function (createElement) {
            return createElement('v-sheet', {
              props: {
                color: self.hexColor(leg.color),
                width: 20,
                height: 20,
              },
              class: {
                'heatmap-cell': true,
                'dotted': leg.dotted
              },
              on: {
                click: function () {
                  self.$emit('changeIdCarteraCobro', imp.IdCarteraCobro)
                }
              }

            })
          }
        })
      }
      return Vue.component('heatmap-cell', {
        functional: true,
        render: function (createElement) {
          return createElement('v-sheet', {
            props: {
              color: 'grey',
              width: 20,
              height: 20,
            },
            on: {
              click: function () {
                self.$emit('changeIdCarteraCobro', imp.IdCarteraCobro)
              }
            }
          })
        }
      })
    }
  }
}
</script>

<style lang="scss">
.heatmap-legend {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 10px;
  padding: 10px;
}
.heatmap-cell {
  cursor: pointer;
}
.theme--dark .heatmap-row {
  border-bottom: thin solid rgba(255, 255, 255, 0.12)
}
.theme--light .heatmap-row {
  border-bottom: thin solid rgba(0, 0, 0, 0.12)
}
.heatmap-legend .heatmap-cell.dotted, .heatmap-cell.dotted {
  border: 2px solid white;
  border-color: white !important;
  border-style: dotted;
}
.heatmap-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  .heatmap-row {
    display: contents;
    // grid-template-columns: 1fr 1fr;
    align-items: center;
    .heatmap-cells {
      gap: 5px;
      .heatmap-cell {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
        grid-template-areas: "nombre nombre" "importe vencimiento";
        justify-content: center;
      }
    }
  }
  // display: flex;
  // flex-wrap: wrap;
  // gap: 6px;
  // .v-card {
  //   display: grid;
  //   grid-template-rows: 0fr 0fr 1fr;
  //   grid-template-columns: 1fr;
  //   justify-content: left;
  //   .v-chip,
  //   .v-icon {
  //     align-self: end;
  //     justify-self: end;
  //   }
  // }
}
</style>